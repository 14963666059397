.container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-element-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient : vertical;
  -webkit-box-direction : normal;
      -ms-flex-direction : column;
          flex-direction : column;
  width: 100%;
  margin-bottom: 28px;
}
.title {
  font-weight : normal;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 0 0 48px;
}
.error-container{
  min-height : 16px;
  margin: 15px;
  width: 350px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.form-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  width: 540px;
  margin-bottom: 8px;
}