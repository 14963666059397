@import '~react-select/dist/react-select.css';

.Select {

}
.Select.is-disabled {
    opacity: 0.3;
}

.Select .Select-control{
    min-width: 100px;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background-color: #f8f8f8;
    border: 2px solid #e5e5e5;
}
.Select .Select-control.is-focused{
     border-color: #8b9294;
     outline: none;
}
.Select.is-open > .Select-control {
    border: 2px solid #e5e5e5;
    border-radius: 25px ;
    background-color: #f8f8f8;
}
.Select.is-focused:not(.is-open) > .Select-control{
    border-color: #8b9294;
    background-color: #f8f8f8;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.Select-menu-outer{
    width: 90%;
    left: 5%;
    margin-top: 1px;
    background-color: white;
    border: none;
    -webkit-box-shadow: 0 2px 15px 0 rgba(61, 67, 69, 0.1);
            box-shadow: 0 2px 15px 0 rgba(61, 67, 69, 0.1);
    border-radius: 8px;
    overflow: hidden;
    z-index: 10;
}

.Select-option.is-focused {
    background-color: #00b1e1;
    color: #fff;
}

.Select-option {
    color: #8b9294;
    font-size: 16px;
    padding: 10px 20px;
}
.Select-option:first-child {
    margin-top: 8px;
}
.Select-option:last-child {
    margin-bottom: 8px;
    border-radius: 0;
}

.Select-placeholder, .Select-input, .Select--single > .Select-control .Select-value {
    top: 2px;
    height: 46px;
    padding-left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.Select-input {
    padding-left: 18px; /* workaround to make the same position as placeholder */
}

.Select-control .Select-input:focus {
    background-color: #f8f8f8;
}

.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    color: #8b9294;
}

.Select-input > input {
    line-height: 30px;
    width: 100%;
}
.Select-control > *:last-child{
    padding-right: 13px;
    padding-top: 3px;
}
.Select-arrow-zone svg{
    fill : #c5c7c7;
}
/* To display only 3 menu items, since filterMaxResults feature is still in PR for react-select */
.Select-menu{
    max-height: 200px;
}
