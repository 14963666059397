.container {
  padding: 10px 0;
  margin: 0;
}

.label {
  margin-bottom: 4px;
}

.text {
  word-wrap: break-word;
  white-space: pre-wrap;
}