@import '../../styles/common/colors';

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  .input {
    margin-top: 24px;
    width: 100%;
    max-width: 600px;
    border: 1px solid $isabeline;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 8px;
    background: $white;
  }

  .search-header {
    position: sticky;
    top: 0px;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #f8f8f8;
    padding-top: 40px;
    margin-top: -24px;
    margin-bottom: 16px;
    width: 1000px;
  }

  .title {
    text-align: center;
  }

  .search-result {
    position: relative;
  }

  .search-limit-notice {
    color: $gray;
    width: 100%;
    max-width: 600px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
