@import "../../../../styles/common/colors";

.item {
  color : $dark-gray;
  text-align: left;
  padding: 10px 24px;
  cursor: pointer;

  &:hover {
    background-color: $isabeline;
  }

  &:active {
    color: $sky-blue;
    background-color: rgba( $light-sky-blue, 0.2);
  }
}


