@import "../../styles/common/text";
@import "../../styles/common/colors";

.title {
  text-align: left;
  margin: 0;
  color: $dark-gray;
}

.sub-title {
  color: $gray;
  font-weight: 300;
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 32px;
  text-align: left;
}

.error-container {
  @extend %paragraph;

  width: 495px;
  min-height : 35px;
  padding: 14px;
  margin-bottom: 20px;

  background-color: rgba($dark-red, 0.1);
  color: $dark-red;
  border-radius: 4px;
  border: 1px solid $dark-red;

  -webkit-box-sizing: border-box;

          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  .icon{
    margin-right: 10px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 24px;
            flex: 0 0 24px;
    width: 24px;
    height: 24px;
    fill: $dark-red;
  }
}

.reset-container {
  height: 65px;
  width: 350px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  border-radius: 3px;
  margin-bottom: 10px;

  background-color: grey;
  color: white;
}

.login-box {
  width: 100%;
  max-width: 495px;
}

.error-container{
  width: 100%;
}