@import "../../styles/common/colors";

.container {
  max-height: calc(100vh - 32px);
  overflow-y: auto;
}

.icon {
  fill: $white;
}

.nav-a {
  text-decoration: none;
}

.separator {
  min-height: 1.2px;
  max-height: 1.2px;

  -ms-flex-negative :0;

      flex-shrink :0;
  -webkit-box-flex:0;
      -ms-flex-positive:0;
          flex-grow:0;

  margin: 10px 24px;
  background-color: $light-gray;

  &.top {
    margin-top: 0;
  }
}
