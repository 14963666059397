.tab-control {
  margin: 0;
  padding: 0;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.grow {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}