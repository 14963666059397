@import '../../styles/common/colors';

.content {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 20px;

  h4 {
    margin: 7px 0 12px;
  }

  p {
    margin: 12px 0;
    color: $gray;
  }

  .icon {
    height: 49px;
    width: 56px;
    margin-right: 32px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    fill: $sky-blue;
  }
}