@import '../../../styles/common/colors';

.file-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 28px;
  width: 134px;
  padding: 0 12px;
  border-radius: 4px;
  color: $gray;
  background-color: rgba($gray, 0.3);
  margin-right: 8px;
  margin-bottom: 8px;

  .file-name {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .close-icon {
    width: 12px;
    height: 12px;
    fill: $gray;
    margin-left: 8px;
    cursor: pointer;
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
}
