@import "../../styles/common/colors";

.header {
  padding: 30px 60px 20px 60px;
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  .title {
    margin: 0;
  }

  .children {
    margin-left: auto;
  }
}

.back {
  font-size: 12px;
  font-weight: 500;
  color: $gray;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  .icon {
    fill: $gray;
    margin-right: 5px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
}