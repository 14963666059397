@import '../../../styles/common/colors';

.status-indicator {
  width: 8px;
  height: 8px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 50%;
  background-color: $light-gray;

  &.active {
    background-color: $dark-green;
  }

  &.base {
    background-color: $silver;
  }

  &.warning {
    background-color: $dark-orange;
  }
}
