@import '../../../styles/common/colors';

.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-top: auto;
  padding: 0 32px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 72px;
  border-top: 2px solid $snow;
}
