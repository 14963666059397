@import '~rc-notification/assets/index.css';

.rc-notification-notice {
    margin: 10px 0;
    padding: 0;
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: 0 2px 15px 0 rgba(61, 67, 69, 0.08);
            box-shadow: 0 2px 15px 0 rgba(61, 67, 69, 0.08);
    overflow: hidden;
}

.rc-notification-fade-enter {
    -webkit-animation-duration: 0.15s;
            animation-duration: 0.15s;
}
.rc-notification-fade-leave {
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
}