@import '../../styles/common/colors';

.secondaryText {
  color: silver;
}

.input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  padding: 0 19.3px 0 19.3px;
  border-radius: 0.5em;
  color: $gray;
  background-color: $snow;
  width: 600px;
  min-height: 50px;
  text-align: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 45px;
}

.domainIcon {
  margin: 15px 0 45px;
  width: 210px;
  height: 210px;
}

