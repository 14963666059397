@import "../../../styles/common/colors";

.table {
  width: 100%;
  table-layout: fixed;

  & > thead > tr > th {
    color: $gray;
    font-weight: normal;
    text-align: left;
  }

  & > thead > tr > th, td {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 20px;
    padding: 0 20px 0 0;
  }

  & > tbody {
    padding-top: 5px;
    color: $dark-gray;
    vertical-align: top;
  }
}