@import '../../../styles/common/colors';

.badge {
  display: inline-block;

  font-size: 13px;
  font-weight: normal;
  font-style: normal;

  border-radius: 12px;
  line-height: 24px;
  height: 24px;
  padding: 0 12px;

  background-color: $isabeline;
  color: $gray;

  max-width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.neutral {
    background-color: $lightest-sky-blue-two;
    color: $dark-sky-blue
  }

  &.positive {
    background-color: $lightest-green;
    color: $dark-green
  }

  &.alert {
    background-color: $lightest-red;
    color: $dark-red
  }

  &.warning {
    background-color: $lightest-orange;
    color: $dark-orange
  }
}
