.list {

  & table {
    table-layout: fixed;
    min-width: 800px;
  }

  & table > thead > tr > th, td {
    &:nth-child(1) {
      width: 44px;
      padding: 0;
    }

    &:nth-child(2) {
      width: 35%;
      padding-left: 0;
    }

    &:nth-child(3), &:nth-child(4) {
      min-width: 100px;
    }

    &:nth-child(5) {
      min-width: 130px;
    }

    &:nth-child(6) {
      width: 50px;
    }
  }

  &.legacy {
    thead {
      display: none;
    }
  }
}
