@import "../../../styles/common/colors";
@import "../../../styles/common/breakpoints";

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 8px;

  background-color: $sky-blue;
  color: $white;

  &.success {
    background-color: $lightest-green;
    color: $black;
  }

  padding: 24px 40px;
  margin-bottom: 24px;
}

.text-and-cta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 16px;
  margin-left: 24px;
}

.text-container {
  .heading {
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
  }

  .description {
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    
    &.success{
      color: $gray;
    }
  }
}

.icon-circle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;

  height: 60px;
  width: 60px;
  border-radius: 30px;

  background-color: $white;
}

.cta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (width <= $mobile-breakpoint){
  .container {
    padding: 16px 20px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .text-and-cta {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

}