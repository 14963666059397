@import "../../styles/common/colors";
@import "../../styles/common/shadow";

$modal-content-padding-left: 60px;
$modal-content-padding-right: 60px;
$modal-content-padding-bottom: 60px;
$modal-border-radius: 8px;

.closeIcon {
  top: 22px !important; /* override native style */
  right: 22px !important;

  width: 37px;
  height: 36px;
  fill: $silver;

  &:hover {
    fill: darken($silver, 10%);
  }
}

.icon {
  -ms-flex-item-align: center;
      align-self: center;
}

.overlay {
  background-color: rgba($light-gray, 0.8) !important;
}

.header {
  height: $modal-border-radius;
  background-color: $sky-blue;
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 57px 60px 40px;
}

.title {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center;
}

.icon + .title {
  -ms-flex-item-align: center;
      align-self: center;
  margin-top: 30px;
}