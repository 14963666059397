@import '../../../styles/common/colors';

.body {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow: auto;
  padding: 32px 48px;
  background-color: $white;
}
