@import "../../styles/common/colors";

.settings-group {
  margin: 20px;
  border: 2px solid $smoke;
  padding: 10px 20px;
  background-color: $white;

  .title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 10px 0;

    h3 {
      margin: 0;
    }

    .toggle {
      margin-left: auto;
    }
  }
  .content  {
    & > * {
      margin: 20px 0;
    }

    &.compact > * {
      margin: 10px 0;
    }
  }
}

.subtitle {
  margin: 10px 0 20px;
  color: $gray;
}