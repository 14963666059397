@import '../../../styles/common/colors';

.tab {
  height: 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 8px;
  cursor: pointer;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.selected {
    border-bottom: 2px solid $sky-blue;
  }
}

.tab + .tab {
  margin-left: 16px;
}