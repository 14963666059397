@import '../../../styles/common/colors';

.table-container {
  table {
    table-layout: fixed;
    width: 100%;

    tr > th {
      font-size: 14px;
    }

    tr > th,
    td {
      height: 56px;
    }
  }
}

.list {
  border: 1px solid $isabeline;
  border-radius: 8px;
  margin-top: 20px;
}
