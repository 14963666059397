@import "../../styles/common/colors";

$circle-size: 12px;
$active-circle-size: 54px;
$spacing: 48px;

.container {
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.pages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: $active-circle-size;

  & > *:not(:last-child) {
    margin-right: $spacing;
  }
}

.page-active {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.circles {
  width: $active-circle-size;
  height: $active-circle-size;
  fill: $sky-blue;
  position: absolute;
}

.page {
  background-color: $sky-blue;
  height: $circle-size;
  width: $circle-size;
  border-radius: 50%;
}

.page-content {
  font-size: 18px;
  font-weight: 500;
  color: $white;
  fill: $white;
  z-index: 2;
}

.title {
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 1.4px;
  color: $gray;
}