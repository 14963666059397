@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.button {
  @extend %paragraph;

  min-width: 130px;
  min-height: 35px;
  padding: 0 42px;
  border-radius: 22.5px;
  outline: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-style: solid;
  border-width: 2px;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  white-space: nowrap;
}

.primary {
  min-height: 45px;
}

.small {
  min-width: 0;
  min-height: 25px;
  padding: 0 25px;
  font-size: 12px;
}

@mixin button-color($color, $hover-color, $focus-color) {
  background-color: $color;
  border-color: $color;
  color: $white;

  &:hover:not(:disabled),
  &.outline:hover:not(:disabled) {
    background-color: $hover-color;
    border-color: $hover-color;
  }

  &:active, &.outline:active {
    background-color: $focus-color;
    border-color: $focus-color;
  }

  &:focus, &.outline:focus {
    border-color: $focus-color;
  }

  &.outline {
    background-color: transparent;
    border-color: $color;
    color: $color;
  }

  &.outline:hover:not(:disabled) {
    color: $white;
  }
}

@mixin button-color-mixed($color) {
  $hover-color: mix(#000000, $color, 10%);
  $focus-color: mix(#000000, $color, 20%);

  @include button-color($color, $hover-color, $focus-color);
}

.button {
  @include button-color-mixed($sky-blue);
}

.warning {
  @include button-color-mixed($orange);
}

.danger{
  @include button-color-mixed($red);
}

.success {
  @include button-color-mixed($green);
}

.midnight {
  @include button-color-mixed($midnight-blue);
}

.gray {
  @include button-color-mixed($silver);
}

.negative-outline {
  background-color: transparent;
  border-color: $white;
  color: $white;

  &:hover:not(:disabled) {
    background-color: transparent;
    border-color: $white;
  }

  &:active {
    background-color: transparent;
    border-color: $white;
  }

  &:focus {
    border-color: $white;
  }
}

.loading {

  &:disabled {
    opacity: 1;
  }

  .loader {
    width: 17px;
    height: 17px;
  }

  &.primary {
    .loader {
      width: 25px;
      height: 25px;
    }
  }

  &.small {
    .loader {
      width: 13px;
      height: 13px;
    }
  }
}