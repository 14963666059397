@import '../../../../styles/common/colors';

.tabs {
  height: 56px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
