.image {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
}

.stretch-horizontally {
  width: 100%;
}