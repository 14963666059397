@import '../../../styles/common/colors';

$headerHeight: 60px;
$sidePanelWidth: 350px;
$sidePanelColor: $white;

//for preview modes
$mobileWidth: 360px;
$mobileHeight: 640px;
$desktopWidth: 1024px;
$desktopHeight: 768px;
$canvasPadding: 10px;
