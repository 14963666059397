@import '../../../styles/common/colors';

.container {
  overflow-y: auto;
}

.input {
  background-color: $white;

  > label {
    padding: 8px 16px;
  }

  &:hover {
    background-color: $snow;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &.selected {
    background: $lightest-sky-blue-two;
  }
}
