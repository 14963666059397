@import '../../../styles/common/colors';

ul.whats-next-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    &::before {
      content: " ";
      margin-right: 10px;
      margin-top: 5px;
      width: 8px;
      height: 8px;
      min-width: 8px;
      border-radius: 50%;
      background-color: $gray;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.doc-link {
  margin-top: 20px;
}