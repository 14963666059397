@import '../../../styles/common/colors';
@import '../../../styles/common/badge';

.header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  border-bottom: 1px solid $smoke;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-weight: normal;

  .status-badge {
    margin-left: 8px;
    margin-right: 16px;
  }

  .type-badge {
    margin-left: 8px;
    margin-right: 16px;
  }

  .label {
    margin-left: 16px;
  }

  .id {
    margin-left: 8px;
  }

  .icon {
    fill: $sky-blue;
    margin-right: 5px;
  }

  .details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .children {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;

    button {
      margin-left: 20px;
    }
  }

  .link {
    text-decoration: none;
  }
}

.header-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.status-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
}

.status-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
