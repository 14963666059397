@import "../../styles/common/colors.scss";

.background{
  background-color: $sky-blue;
  width: 100%;
  height:100%;
  margin : 0;
  display: block;
}

.lost-track-background-image{
  background-image : url("/images/lost-track.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top:  20%;
  left: 5%;
  bottom:0%;
  right : 25%;
  height: 80%;
  width: 70%;
}

.header {
  height: 64px;
  font-family: Radikal;
  font-size: 104px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.62;
  letter-spacing: -0.8px;
  color: #003c4d;
  padding : 25px 0 25px 0;
}

.sub-header , .upper-header{
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $white;
}

.upper-header{
  text-transform: uppercase;
}

.main-content {
  position: absolute;
  top: 30%;
  left: 5%;
  text-align: left;
}

.logo-white {
  top: 5%;
  left: 2%;
  position: absolute;
}