@import '../../../styles/common/colors';

.heading {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  text-transform: uppercase;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 1.75px;
  margin-bottom: 20px;
}
