.wrapper {
  display: block;
  height: 100%;
  margin: 0 0 20px;
}

.wrapper.fill {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}