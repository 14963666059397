@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.icon {
  fill: $silver;
  margin-left: -20px;
  height: 64px;
}

.name {
  @extend %paragraph;
  color: $dark-gray;
  letter-spacing: 0.1px;
  margin-right: 10px;
}

.date {
  @extend %paragraph;
  color: $gray;
  vertical-align: middle;
}

.warn-icon {
  margin-left: 10px;
  display: inline-block;
  fill: $gray;
  vertical-align: middle;
}

.edit-icon {
  width: 32px;
  height: 32px;
  fill: $gray;
}

.group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  line-height: 1;
}
