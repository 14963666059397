@import "../../../styles/common/colors";

.container {
  min-width : 380px;
  padding-left: 20px;
}

.heading {
  margin-bottom: 16px;
}

.carrier-logo {
  padding: 16px 0;
  max-width: 160px;
}

.carrier-service, .order-state {
  text-transform: capitalize;
}