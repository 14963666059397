@import "../../../styles/common/colors";

@mixin input-color-mixin($color, $hover-color, $focus-color) {
  & + .indicator {
    border: 2px solid $color;
    fill: $color;
  }

  /* we need an input element focus selector to apply style to indicator element */
  &:focus + .indicator {
    border-color: $focus-color;
    fill: $focus-color
  }

  &:not(:focus) + .indicator:hover {
    border-color: $hover-color;
    fill: $hover-color;
  }
}

@mixin input-mixin() {
  /* hide input */
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;

  & + .indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    height: 24px;
    box-sizing: border-box;
    margin-right: 14px;
    background-color: $snow;
    border-radius: 2px;
  }

  &:disabled ~ .indicator {
    opacity: 0.3;
    cursor: default;
  }

  @include input-color-mixin($light-gray, $smoke, $silver);

  &:checked {
    @include input-color-mixin($green, $green-light, $dark-green);
  }

  &:not(:checked) + .indicator > svg {
    display: none;
  }
}