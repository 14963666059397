@import "../../../styles/common/colors";
@import "../../../styles/common/text";

.text {
  @extend %paragraph;
  color: $gray;

  &.small {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }

  &.ink {
    color: $dark-gray;
  }

  &.title {
    @extend %paragraph-title;
  }
}
