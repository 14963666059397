@import "../../../styles/common/colors";


.container {
  width: 100%;
  height: 56px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  margin-top: -1px;
  border-top: 1px solid $light-gray;

  padding-left: 25px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;


  .input {
    height: 46px;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $gray;
    outline: none;
    border: none;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;

    &::-webkit-input-placeholder {
      color: $silver;
    }

    &::-moz-placeholder {
      color: $silver;
    }

    &::-ms-input-placeholder {
      color: $silver;
    }

    &::placeholder {
      color: $silver;
    }
  }

  .clear-button {
    height: 54px;
    outline: none;
    border: none;
    background: none;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $silver;
    padding: 0;
    margin: 0 15px;
    cursor: pointer;

    &:hover {
      color: darken($silver, 10%);
    }

    &:active {
      color: darken($silver, 20%);
    }
  }

  .search-button {
    outline: none;
    border: none;
    padding: 0;
    border-bottom-right-radius: 8px;
    border-left: 1px solid $light-gray;

    width: 56px;
    height: 46px;
    cursor: pointer;
    background-color: $white;

    &:hover,
    &:focus,
    &:active {

      .icon {
        fill: $dark-sky-blue;
      }
    }

    .icon {
      width: 24px;
      height: 24px;
      fill: $sky-blue;
    }
  }
}