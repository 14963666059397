@import '../../../styles/common/colors';

@mixin badge-color($color, $bgColor: lighten($color, 30%)) {
  background-color: $bgColor;
  color: $color;
}

.badge {
  display: inline-block;

  font-size: 13px;
  font-weight: normal;
  font-style: normal;

  border-radius: 12px;
  line-height: 24px;
  height: 24px;
  padding: 0 12px;

  background-color: $isabeline;
  color: $gray;

  &.neutral {
    @include badge-color($sky-blue);
  }

  &.positive {
    @include badge-color($dark-green);
  }

  &.alert {
    @include badge-color($dark-red);
  }

  &.warning {
    @include badge-color($dark-orange);
  }

  &.attention {
    @include badge-color($white, $dark-orange);
  }

  &.success {
    @include badge-color($snow, $dark-green);
  }
}
