.image-container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 50%; /* needed to prevent disproportion between columns */
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.image {
  min-height: 400px; /* we want to keep the same size for different tabs */
  max-height: 400px;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
}
