@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.header {
  cursor: pointer;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: $snow;

  &:hover {
    background-color: $smoke;
  }

  &.active {
    background: $lightest-sky-blue-two;
  }

  .icon-up {
    margin: 0 4px;
    fill: $dark-sky-blue;
  }

  .icon-down {
    margin: 0 4px;
    fill: $dark-gray;
  }

  .label {
    @extend %paragraph;
    color: $dark-gray;

    &.active {
      color: $dark-sky-blue;
    }
  }
}
