.label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  &.disabled {
    opacity: 0.3;
    cursor: default;
  }

  & > * {
    margin-top: 10px;
  }
}

.inline {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  line-height: 24px;

  & > * {
    margin-top: 0;
  }
}

.inline-left {
  & > *:last-child {
    margin-left: 10px;
  }
}

.inline-right {
  & > *:first-child {
    margin-right: 10px;
  }
}
