@import '../../components/editors/common/editorVars';

.canvas {
  overflow: auto;
  max-width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.mobile-popup {
  margin: 20px 67px;
}
/* we have to wrap preview into container to prevent margins and paddings collapsing when overflow */
.preview-container {
  padding: $canvasPadding;
}

.preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  background-color: white;
  -webkit-box-shadow: 0 0 15px 0 rgba(61, 67, 69, 0.1);
          box-shadow: 0 0 15px 0 rgba(61, 67, 69, 0.1);
}

.children {
  /* allow children (widget or tracking-page) grows to minimum height (for better look if we have a footer) */
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  width: 100%;
}

.desktop {
  min-width: 2 * $canvasPadding + $desktopWidth;
  height: 100%;
  & .preview {
    min-width: $desktopWidth;
    min-height: $desktopHeight;
  }
}

.mobile {
  /* auto margin work better then self-align: center because of scroll and margins */
  margin-left: auto;
  margin-right: auto;
  min-width: 2 * $canvasPadding + $mobileWidth;
  min-height: 2 * $canvasPadding + $mobileHeight;

  & .preview {
    max-width: $mobileWidth;
    min-width: $mobileWidth;
    min-height: $mobileHeight;
  }
}

.email-template {
  &.mobile {
    min-height: initial;
  }

  .preview {
    min-height: initial;
  }
}