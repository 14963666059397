@import '../BasicInput/mixins';

.input {
  @include input;
  font-family: 'Public Sans', sans-serif; /* textarea overrides font with monospace */

  min-height: 64px;
  resize: none;

  &.error {
    @include error;
  }
}
