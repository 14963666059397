@import '../../../styles/common/colors';

.item {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.icon {
  margin-right: 12px;
  width: 20px;
  height: 20px;
}
