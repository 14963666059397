.table-header th, .table-body td {
  &:nth-child(1) {
    width: 100px;
  }

  &:nth-child(2) {
    width: 140px;
  }

  &:nth-child(3) {
    width: 100%;
  }
}

.table-body {
  td:nth-child(3) {
    text-transform: uppercase;
  }
}
