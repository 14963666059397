@import '../../styles/common/colors';

.content {
  margin-bottom: 30px;
}

.bottom {
  background-color: $snow;
  margin-top: 60px;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}