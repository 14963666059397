@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  padding: 0 20px;
  line-height: 0;

  background-color: $white;
  -webkit-box-shadow: 0 2px 10px 0 rgba(241, 241, 241, 0.2);
          box-shadow: 0 2px 10px 0 rgba(241, 241, 241, 0.2);
  border-radius: 8px;
  overflow: hidden;

  th, td {
    border-bottom: 1px solid $smoke;
    padding: 0 5px;
  }

  th {
    @extend %paragraph-small;
    line-height: 0;
    color: $gray;
    height: 27px;
    text-align: left;
    letter-spacing: normal;
  }

  tr {
    background-color: $white;
  }

  td {
    @extend %paragraph;
    height: 64px;
    color: $gray;
  }

  tr:last-child td {
    border: 0;
  }
}