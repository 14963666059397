@import '../../styles/common/text';
@import '../../styles/common/colors';

.title {
  @extend %paragraph;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 10px;
  color: $dark-gray;

  &:hover {
    color: $sky-blue;
  }
}
