@import '../../styles/common/colors';
@import '../../styles/common/text';

.notification {
  border-radius: 5px;
  border: 1px solid $gray;
  background-color: $snow;

  .content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 16px 16px;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    fill: $gray;
  }

  .message {
    @extend %paragraph;
    color: $dark-gray;
  }
}

.info {
  border: 1px solid $dark-sky-blue;
  background-color: $lightest-sky-blue-two;
  .icon {
    fill: $dark-sky-blue;
  }
}

.success {
  border: 1px solid $dark-green;
  background-color: $lightest-green;
  .icon {
    fill: $dark-green;
  }
}

.warning {
  border: 1px solid $dark-orange;
  background-color: $lightest-orange;
  .icon {
    fill: $dark-orange;
  }
}

.error {
  border: 1px solid $dark-red;
  background-color: $lightest-red;
  .icon {
    fill: $dark-red;
  }
}
