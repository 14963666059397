@import "../../../styles/common/colors";
@import "../../../styles/common/shadow";

.card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px;
  border-radius: 8px;
  background-color: $white;
  margin: auto;
  text-align: left;

  @include shadow();

  &.noPadding {
    padding: 0px;
  }
}

.title {
  color: dimgray;
  font-size: 1.5em;
  margin-bottom: 20px;
}
