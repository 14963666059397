@import '../../../../styles/common/colors';
@import '../../../../styles/common/badge';


.status-badge {
  @include medium-badge($midnight-blue);

  &:before {
    @include badge-dot();
  }
  &.published {
    background-color: $green;
  }
}