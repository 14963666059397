@import '../../../styles/common/colors';

.dropdown {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  margin-top: 6px;
  top: 36px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 5px;
  width: 100%;
  min-width: 186px;
  min-height: 40px;
  max-height: 300px;
  border-radius: 4px;
  background-color: $white;
  -webkit-box-shadow: 0 0 0 1px #ececec, 0 2px 16px 0 rgba(45, 50, 51, 0.1);
          box-shadow: 0 0 0 1px #ececec, 0 2px 16px 0 rgba(45, 50, 51, 0.1);

  button {
    border-top: 1px solid $isabeline;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 12px 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
  }
}
