@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.public-link {
  @extend %paragraph-small;
  display: block;
  margin-top: 1px;
  word-wrap: break-word;
  text-decoration: none;

  &:hover,
  &:active {
    color: mix(#000000, $gray, 10%);
  }
}