@import '../../../styles/common/text';
@import '../../../styles/common/colors';

.localizationList{
  table {
    table-layout: fixed;
    width: 100%;
    padding-top: 15px;
  }

  table > thead > tr > td {
    height: 64px;
  }

  table > thead > tr > th, td {
    &:nth-child(1) {
      width: 64px;
      padding: 0;
    }

    &:nth-child(2) {
      width: 336px;
      & span {
        color: $dark-gray;
        letter-spacing: 0.1px;
      }
    }

    &:nth-child(3) {
      width: 100%;
    }

    &:last-child {
      padding: 0 10px;
      width: 50px;
    }
  }

}