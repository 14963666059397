@import '../../styles/common/colors';

.localization-header {
  height: 23px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
  fill: $silver;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  margin: 0 16px 0 0;
  -o-object-fit: contain;
     object-fit: contain;
}

.url {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $dark-gray;
}

.small-badge {
  margin: 3px 8px;
}