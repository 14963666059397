@import '../../../styles/common/colors';

.header {
  position: sticky;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  justify-items: baseline;
  top: 0;
  padding-top: 24px;
  margin-top: -24px;
  background: $snow;
  min-width: 942px;
  z-index: 1;
}
