@import '../../../styles/common/colors';

.header {
  position: sticky;
  top: 0;
  height: 80px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 24px 0 24px 0;
  margin-top: -24px; /* prevents extra margin to appear on header */
  z-index: 10; /* when screen height is too small preview might overlap header buttons */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  .heading {
    margin-right: auto;
  }

  background-color: $snow;
}
