@import '../../../styles/common/colors';

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.icon {
  fill: $sky-blue;
  margin-bottom: 8px;
}

.tip {
  display: inline;
}
