@import "./fonts";
@import "./common/colors";
@import "./common/text";

html,
body,
:global(#root) {
  @extend %paragraph;

  font-family: 'Public Sans', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
}

h1 {
  font-family: 'Radikal', sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: left;
  color: $dark-gray;
  margin: 0;
}

h2 {
  font-family: 'Radikal', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 34px;
  text-align: left;
  color: $gray;
  margin: 0;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: $dark-gray;
  margin: 0;
}

h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: $dark-gray;
  margin: 0;
}