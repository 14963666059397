@import "../../../styles/common/colors";

.message {
  color: $gray;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 8px;
}

.icon {
  fill: $sky-blue;
  margin-right: 12px;
}