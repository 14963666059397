@import "../../styles/common/colors";

.form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.error {
  font-size: 14px;
  color: red;
  margin-top: 5px;
}

.login-button {
  margin-top: 24px;
  -ms-flex-item-align: center;
      align-self: center;
}

.forgot-password {
  position:relative;
  margin-top: 32px;

  .forgot-password-text {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    color: $gray;

    &:hover {
      color: darken($gray, 10%);
    }
  }
}

