@import "../../../../styles/common/colors";

.container {
  position: relative;
  display: inline-block;
}

.button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 32px 20px;

  -webkit-box-sizing: border-box;

          box-sizing: border-box;
  width: 210px;
  height: 190px;
  border-radius: 5px;
  border: solid 1px $isabeline;
  outline: none;
  background: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    background-color: $snow;
  }

  &:hover:enabled {
    border: solid 2px $sky-blue;
    padding: 31px 19px; /* compensates 1px different between normal and hover border */
  }

  .description {
    margin-top: 4px;
    word-break: break-word;
  }
}

@mixin icon-color($color) {
  background-color: rgba($color, 0.15);

  .icon {
    fill: $color;
  }
}

.icon-circle {
  margin-bottom: 16px;

  width: 48px;
  height: 48px;
  border-radius: 50%;

  .icon {
    margin: 12px;
    width: 24px;
    height: 24px;
  }

  &.primary {
    @include icon-color($sky-blue);
  }

  &.warning {
    @include icon-color($dark-orange);
  }

  &.danger {
    @include icon-color($dark-red);
  }
}
