@import "../../../styles/common/colors";

input[type="checkbox"].toggle {
  /* hide input */
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;

  &:disabled + .indicator {
    opacity: 0.3;
    cursor: default;
    cursor: not-allowed;
  }

  &:checked + .indicator {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    background-color: $green;
    border-color: $green;

    &:hover {
      background-color: $dark-green;
      border-color: $dark-green;
    }

    & > .knob {
      margin-left: 0;
      margin-right: -2px;
    }

    & > .label {
      color: $white;
    }
  }
}

.toggle-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}

.indicator {
  min-width: 45px;
  height: 20px;
  border-radius: 10px;
  background-color: $white;
  border: 2px solid $light-gray;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  &:hover {
    background-color: $snow;
    border-color: $silver;

    & > .knob {
      background-color: $snow;
      border-color: $silver;
    }

    & > .label {
      color: $silver;
    }
  }

  & > .knob {
    margin-left: -2px;
    width: 24px;
    height: 24px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    border-radius: 50%;
    background-color: $white;
    border: 2px solid $light-gray;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }

  & > .label {
    padding: 0 10px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    white-space: nowrap;

    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: normal;
    color: $light-gray;
  }
}