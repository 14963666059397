@import "./shadow";
@import "./colors";

@mixin card($width, $height, $padding) {
  width: $width;
  height: $height;
  border-radius: 8px;
  background-color: $white;
  padding: $padding;
  box-sizing: border-box;

  @include shadow();
}