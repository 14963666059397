@import '../BasicInput/mixins';

$arrow-element-width: 32px;

.select {
  @include input;

  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding-right: $arrow-element-width;

  &:disabled {
    cursor: default;
  }

  &:focus:not(:disabled) {
    padding-right: $arrow-element-width;
  }
}

.select-wrapper {
  position: relative;
}

.arrow-element {
  position: absolute;
  right: 0;
  top: 0;

  pointer-events: none;

  height: 36px;
  padding: 0 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  fill: $dark-gray;
}

.select:disabled + .arrow-element {
  fill: $silver;
}