@import "../../styles/common/colors";
@import "../../styles/common/breakpoints";

.shop-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 60px;
  width: 100%;
  padding: 0 24px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;

  .circle{
    -ms-flex-negative: 0;
        flex-shrink: 0;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: $sky-blue;

    .initials {
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      font-size: 16px;
      color: white;
      height: 32px;
      width: 100%;
    }
  }

  .data {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-left: 10px;
    white-space: nowrap;
  }

  &:hover {
    background-color: $isabeline;
  }

  &:active, &.selected  {
    background-color: rgba($light-sky-blue, 0.2);

    .header {
      color: $dark-sky-blue;
    }
  }
}

/* quick workaround to make it a bit better fitting into mobile menu because it's not an selectable item in there */
@media (max-width: $mobile-breakpoint) {
  .shop-card {
    cursor: default;

    &:hover, &:active {
      background-color: inherit;
    }
  }
}