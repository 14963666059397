@import "colors";

%large {
  font-family: 'Radikal', sans-serif;
  font-weight: 700;
  font-size: 60px;
  line-height: 64px;
  letter-spacing: -0.8px;
  text-align: left;
}

%paragraph-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $dark-gray;
}

%paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $dark-gray;
}

%paragraph-small {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: $dark-gray;
}

%label {
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}
