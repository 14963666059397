@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 300px;
  height: 84px;
  background: $white;
  border: 1px solid $isabeline;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
  border-bottom: 4px solid $sky-blue;

  &.warning {
    border-bottom-color: $orange;
  }

  &.positive {
    border-bottom-color: $dark-green;
  }

  .value {
    font-family: 'Radikal', sans-serif;
    font-size: 26px;
    line-height: 40px;
    color: $dark-gray;

    &:empty:before {
      content: '-';
    }
  }
}
