$columns-spacing: 10px;
$rows-spacing: 12px;

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;

  th {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 $columns-spacing 4px 0;
  }

  td {
    padding: 0 $columns-spacing $rows-spacing 0;
  }

  td, th {
    &:last-child {
      padding-right: 0;
    }
  }

  .amount {
    width: 64px;
  }

  .name, .description {
    width: 100%;
  }

  .button {
    width: 20px;
  }
}

.error {
  margin-bottom: 12px;
}