@import '../../../styles/common/colors';

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 64px;
  padding: 0 24px;
  background-color: $snow;
  -ms-flex-negative: 0;
      flex-shrink: 0;

  .heading {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    text-align: center;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .contact-support {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    width: 24px;
    height: 24px;
    fill: $sky-blue;
    cursor: pointer;

    &:hover {
      fill: darken($sky-blue, 10%);
    }
  }
}
