@import '../../../styles/common/colors';

.modal {
  border-radius: 4px;
  background-color: $white;
  padding: 0 !important;
  width: 600px;
  -webkit-box-shadow: 0 2px 16px 0 rgba(45, 50, 51, 0.1);
          box-shadow: 0 2px 16px 0 rgba(45, 50, 51, 0.1);
}

.closeButton {
  top: 16px !important;
  right: 19px !important; /* override native style */
  cursor: pointer;
  outline: none;
}

.closeIcon {
  width: 24px;
  height: 40px;
  fill: $black;

  &:hover {
    fill: darken($silver, 10%);
  }
}

.overlay {
  background-color: rgba($dark-gray, 0.4) !important;
}
