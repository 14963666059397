@import "../../../styles/common/colors";

.handle {
  cursor: -webkit-grab;
  cursor: grab;
  margin-left: auto;
  fill: $silver;
  &:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
}
