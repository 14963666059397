@import '../../../../styles/common/colors';

.information {
  padding: 0;

  .icon {
    width: 12px;
    height: 12px;
    fill: $gray;
    display: block;
  }

  .description {
    margin: 5px 0 10px 0;
  }
}