.form-element-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient : vertical;
  -webkit-box-direction : normal;
      -ms-flex-direction : column;
          flex-direction : column;
  width: 100%;
  margin-bottom: 50px;
}
.title {
  -ms-flex-item-align: center;
      align-self: center;
  margin: 0;
}

.subtitle {
  font-size: 16px;
  line-height: 26px;
  margin: 10px 0 45px;
}

.error-container{
  min-height : 35px;
  margin: 15px;
  width: 350px;
  font-weight: bold;
}
.formSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  width: 540px;
}

.success-message-container{
  width: 360px;
  height: 23px;

  background-color: darkgrey;
  color: white;

  margin: 20px;
  padding: 30px;

  border-radius: 5px;

  text-align: center;
}

.back-link {
  margin-top: 20px;
}