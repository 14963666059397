@import "../../../styles/common/colors";

.footer {
  height: 70px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0 24px;
  background-color: $white;
  border-top: 2px solid $snow;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}