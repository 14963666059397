@import '../editors/common/editorVars';
@import '../editors/mixins';
@import '../../styles/common/colors';

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: 100%;
  width: 100%;
  position: relative;

  .side-panel {
    width: $sidePanelWidth;
    height: 100%;
  }

  .main {
    max-width: calc(100% - 350px);
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    height: 100%;

    @include header-body-container();

    .header, .body {}
  }
}

.expand-button {
  cursor: pointer;

  position: absolute;
  bottom: 100px;
  padding: 10px;
  background-color: $white;
  z-index: 1;
  left: $sidePanelWidth;

  .expand-icon {
    color: $black;
  }
}

.collapsed {

  .main {
    max-width: none;
  }

  .expand-button {
    left: 0;
  }

  .expand-icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  .side-panel {
    width: 0;
  }
}