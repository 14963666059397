@import '../../../styles/common/colors';

.link-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 80px;
  padding: 20px 32px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: $white;
  border-radius: 40px;
  border: solid 1px $isabeline;

  .icon {
    margin-right: 16px;
  }

  .tip {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
