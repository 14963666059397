@import "../../../styles/common/shadow";
@import "../../../styles/common/colors";

.form {
  background-color: $white;
  padding: 50px;
  border-radius: 8px;

  @include shadow();

  .field {
    margin-bottom: 30px;
    width: 100%;
  }

  .field + .icon-select {
    width: 100px;
  }

  .buttons {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;

    & .default {
      margin-right: auto;
    }

    & .save {
      margin-left: 10px;
    }
  }
}