@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  @extend %paragraph-small;

  color: $sky-blue;
  cursor: pointer;
  text-decoration: none;

  .icon {
    fill: $sky-blue;
    margin-right: 5px;
  }

  &:hover .icon {
    margin-right: 10px;
  }

  &:hover,
  &:active {
    color: mix(#000000, $sky-blue, 10%);
  }
}
