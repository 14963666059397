@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.table {
  table-layout: fixed;
  width: 100%;
  min-width: 800px;
  overflow: hidden;
  line-height: 0;

  background-color: $white;
  border: 1px solid $isabeline;
  border-spacing: 0;
  border-radius: 5px;
  border-collapse: separate;

  &.no-header-top-border {
    border-top: none;
  }

  th, td {
    border-bottom: 1px solid $isabeline;
    padding: 0 24px;
  }

  th {
    @extend %paragraph-small;
    color: $gray;
    height: 52px;
    text-align: left;
  }

  tr {
    background-color: $white;
  }

  td {
    @extend %paragraph;
    height: 76px;
    color: $gray;
  }

  tr:last-child td {
    border: 0;
  }
}