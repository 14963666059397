@import "../../../styles/common/colors";

.drawer {
  right: 0;
  top: 0;
  z-index: 20;
  position: absolute;
  height: 100%;
  max-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  background-color: $white;
  overflow: hidden;
  -webkit-transition: width 250ms cubic-bezier(0.15, 1, 0.3, 1) 0s;
  transition: width 250ms cubic-bezier(0.15, 1, 0.3, 1) 0s;
  width: 0;
  -webkit-box-shadow: 0 2px 20px 0 rgba(139, 146, 148, 0.1);
          box-shadow: 0 2px 20px 0 rgba(139, 146, 148, 0.1);

  &-enter, &-enter-done {
    width: 692px;
  }
}

.background {
  opacity: 0;
  -webkit-transition: opacity 250ms ease 0s;
  transition: opacity 250ms ease 0s;

  &-enter, &-enter-done {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: $gray;
    opacity: 0.8;
    z-index: 10;
  }
}