@import "../../../styles/common/colors";

@mixin label-color($color) {
  color: $color;

  .icon {
    fill: $color;
  }
}

.status-label {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  .icon {
    margin-right: 5px;
    width: 12px;
    height: 12px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }

  &.info {
    @include label-color($gray);
  }

  &.warning {
    @include label-color($orange);
  }

  &.error {
    @include label-color($red);
  }

  &.success {
    @include label-color($green);
  }
}