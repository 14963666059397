@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.container {
  @extend %paragraph;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  cursor: pointer;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  input[type='checkbox'].checkbox {
    /* hide input */
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;

    & + .indicator {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 16px;
      height: 16px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      margin-right: 8px;
      border-radius: 3px;
      border: 1px solid $light-gray;
      fill: $snow;
    }

    &:focus + .indicator {
      outline: none;
      border-color: $sky-blue;
    }

    &:checked + .indicator {
      border-color: $sky-blue;
      fill: $sky-blue;
    }

    &:disabled + .indicator {
      border-color: $smoke;
    }

    &.error + .indicator {
      border-color: $dark-red;
      fill: $dark-red;
    }

    &:not(:checked) + .indicator > svg {
      display: none;
    }
  }
}
