@import "../../../styles/common/colors";

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  text-align: left;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: auto;
}

.content{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  .title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-top: 12px;
    margin-bottom: 8px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    .badge {
      margin-left: 10px;
    }
  }

  .description {
    margin-bottom: 16px;
  }

}

.icon {
  margin-right: 12px;
}