@import "../../../styles/common/colors";

.icon {
  fill: $sky-blue;
  height: 10px;
}

.view-link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}