@import '../../../styles/common/colors';

.container {
  margin-top: 16px;
  background-color: $white;
  border: 1px solid $isabeline;
  border-spacing: 0;
  border-radius: 5px;

  .heading {
    padding: 24px 32px;
    border-bottom: 1px solid $isabeline;
  }

  .results {
    padding: 24px 32px;
  }
}

.separator {
  height: 1px;
  background-color: $isabeline;
  margin: 24px 0;
}

.failed-messages {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 5px;
}
