@import "../../../styles/common/colors";
@import "../../../styles/common/card";

$width: 420px;
$left-border-width: 4px;

.card {
  @include shadow();

  width: $width;

  -webkit-box-sizing: border-box;

          box-sizing: border-box;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;

  margin-bottom: 16px;
  margin-left: 24px;
  -ms-flex-negative: 0;
      flex-shrink: 0;

  .content {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    border: 1px solid $isabeline;
    border-left: none;
    background-color: $white;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 20px 24px 24px 24px;
  }

  .left-border {
    width: $left-border-width;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    -ms-flex-negative: 0;
        flex-shrink: 0;

    background-color: $silver;

    &.really_bad {
      background-color: $dark-red;
    }

    &.bad {
      background-color: $dark-orange;
    }

    &.good {
      background-color: $dark-green;
    }

    &.neutral {
      background-color: $sky-blue;
    }
  }
}

.status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.status-text {
  margin-bottom: 16px;
}