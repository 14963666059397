.alignment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;

  & > * {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }

  & > *:not(:last-child) {
    margin-right: 5px;
  }
}

.alignment-name {
  text-align: center;
}