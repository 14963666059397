@import "../../styles/common/card";
@import "../../styles/common/text";

.card {
  @include card(550px, 130px, 30px);

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;

  .icon {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-right: 30px;
  }

  .content {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    .title {
      margin: 0 0 10px;
    }

    .description {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      margin-bottom: 15px;
      @extend %paragraph;
      color: $gray;
    }
  }
}