.container {
  padding: 0 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  .edit-form {
    min-width: 640px;
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
  }

  .preview {
    margin-left: 40px;
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    max-width: 640px;
  }
}