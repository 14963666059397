@import "../../styles/common/colors";

.theme{
  padding: 12px 21px 11px 18px;
  color: $snow !important;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.25;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 15px 0 rgba(61, 67, 69, 0.1);
          box-shadow: 0 2px 15px 0 rgba(61, 67, 69, 0.1);
  width: auto;
  text-align: left;
  opacity: 1 !important;
}