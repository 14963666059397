.buttons {
  margin-top: 40px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;

  button + button {
    margin-left: 20px;
  }

  &.center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  &.right {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}