@import '../../../styles/common/colors';

.container {
  background-color: $white;
  border: 1px solid $isabeline;
  border-spacing: 0;
  border-radius: 5px;
  padding: 24px 42px;
}

.results {
  margin-top: 16px;
}
