@import "../../../styles/common/colors";

$circle-size: 16px;
$line-width: 2px;

.segment {
  width: 100px;
  max-width: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-negative: 0;
      flex-shrink: 0;

  .pre, .content {
    border-right: 2px solid $isabeline;
  }

  .pre {
    height: 24px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }

  .content {
    position: relative; /* needed to position circle element */
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }

  .date, .time {
    text-align: right;
    padding-right: 24px;
  }

  .date {
    color: $dark-gray;
  }

  .time {
    color: $gray;
  }

  .circle {
    position: absolute;
    top: 0;
    right: calc(0px - ($circle-size / 2) - ($line-width / 2));
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;

    border: 2px solid $light-gray;
    background-color: $snow;
  }

  &.last-event {
    .pre {
      border: none;
    }

    .circle {
      border: 6px solid $sky-blue;
      background-color: $white;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
    }
  }

  &.first-event {
    .content {
      border: none;
      margin-right: $line-width; /* to compensate border width */
    }
  }
}