.list {
  display: grid;
  grid-template-columns: 1fr 1fr 20px;
  gap: 8px;

  margin-top: 4px;

  & > .item {
    -ms-flex-item-align: center;
        align-self: center;
  }
}