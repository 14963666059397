.link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  text-decoration: none;
  margin-bottom: 6px;
}

.link:only-child {
  margin-bottom: 0px;
}
