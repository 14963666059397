@import '../../../styles/common/colors';

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 4px;
  border-radius: 3px;
  background: $isabeline;
  width: 100%;

  .bar {
    height: 100%;
    border-radius: 3px;
    background: $sky-blue;
  }
}
