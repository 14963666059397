@import "../../../styles/common/colors";

$timeline-circle-size: 16px;
$timeline-segment-top-padding: 24px;

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 544px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.heading {
  margin-bottom: 16px;
}

.event-container {
  -ms-flex-negative: 0;
      flex-shrink: 0;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}