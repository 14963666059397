@import '../../../../styles/common/colors';
@import '../editorVars';

.preview-mode-tabs {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.tab-icon {
  width: 64px;
  fill: rgba(255, 255, 255, 0.2);
  cursor: pointer;

  &.selected {
    fill: $white;
    cursor: default;
  }

  & + .tab-icon {
    margin-left: -18px;
  }
}


.bluish {
  .tab-icon {
    fill : $sky-blue;
    opacity: 0.2;

    &.selected {
      fill: $sky-blue;
      opacity: 1;
    }
  }
}
