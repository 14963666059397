@import '../../../styles/common/text';

@mixin input {
  height: 36px;
  width: 100%;
  padding: 6px 12px;
  box-sizing: border-box;
  font-family: inherit;
  border-radius: 4px;
  border: 1px solid $light-gray;
  background-color: $snow;
  caret-color: $sky-blue;
  color: $dark-gray;

  @extend %paragraph;

  &:focus:not(:disabled) {
    border: 2px solid $sky-blue;
    padding: 5px 11px;
    outline: none;
  }

  &:disabled {
    border-color: $smoke;
    background-color: $snow;
    color: $silver;
  }

  &::placeholder {
    color: $gray;
  }

  &:disabled::placeholder {
    color: $silver;
  }
}

@mixin error {
  background-color: rgba($dark-red, 0.1);
  border-color: $dark-red;

  &:focus:not(:read-only) {
    background-color: rgba($dark-red, 0.1);
    border-color: $dark-red;
  }
}
