@import '../../../styles/common/colors';

.localization-icon {
  fill: $silver;
  margin-left: -20px;
  display: block;
}

.edit-icon {
  width: 32px;
  height: 32px;
  fill: $gray;
}

.mediumBadge{
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: $white;
  background-color: $dark-gray;
  padding: 3px 8px 4px;
  border-radius: 2px;
  white-space: nowrap
}
