@import "../../../styles/common/colors";
@import "../../../styles/common/breakpoints";

.card {
  border-radius: 8px;
  border: 1px solid $isabeline;
  padding: 24px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;

  &.neutral {
    background-color: $white;
  }

  &.positive {
    background-color: $lightest-green;
  }
}

@media (max-width: $mobile-breakpoint){
  .card {
    padding: 20px 16px;
  }
}