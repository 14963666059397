@import "../../styles/common/colors";

.container {
  min-width: 64px;
  max-width: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: $white;
  background-color: $sky-blue;
}

.logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 24px;
}

.settings {
  margin-left: auto;
}

.product-menu {
  -ms-flex-item-align: stretch;
      align-self: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.secondary-menu {
  margin: auto 0 4px 0;
}

.link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
