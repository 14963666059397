@import '../../../styles/common/colors';

.small-badge {
  display: inline-block;
  height: 16px;
  padding: 1px 8px;
  border-radius: 2px;
  background-color: $dark-gray;
  font-size: 10px;
  line-height: 16px;
  color: $white;

  &.selected {
    background-color: $midnight-blue;
  }
}