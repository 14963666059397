@import '../../styles/common/colors';

.menu {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: $white;
  -webkit-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.25);
          box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.25);
  padding: 0 12px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  & > .item {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    height: 40px;
    width: 100%;
    padding: 8px 24px;
    border-radius: 5px;
    cursor: pointer;

    margin-top: 2px;
    margin-bottom: 2px;

    &:hover {
      color: $charcoal-gray;
      background-color: $isabeline;
    }
  }

  & > .devider {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    height: 1px;
    width: auto;
    margin: 0 12px;
    background-color: $silver;
  }

  & > .expander {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
}