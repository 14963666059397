@import '../../styles/common/colors';

.list {
  width: 100%;
  min-width: 800px;
  margin-bottom: 10px;
  overflow: hidden;
  line-height: 0;
  background-color: $white;
  border: 1px solid $isabeline;
  border-spacing: 0;
  border-radius: 5px;
  border-collapse: separate;

  &.expanded {
    margin-bottom: 30px;
  }

  &:last-child {
    margin-bottom: 30px;
  }
}