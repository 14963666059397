@import '../../../styles/common/colors';

.container {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  background-color: $white;
  border: 1px solid $isabeline;
  border-spacing: 0;
  border-radius: 5px;

  .heading {
    padding: 16px 52px;
    border-bottom: 1px solid $isabeline;
  }

  .steps {
    padding: 24px 52px;
  }
}