@import "./common";

.input {
  @extend %input-shared;
}

.input-container {
  position: relative;

  .icon {
    position: absolute;

    height: 50px;
    width: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .icon-left {
    left: 0;

    & ~ .input {
      padding-left: 50px;
    }
  }

  .icon-right {
    right: 0;

    & ~ .input {
      padding-right: 50px;
    }
  }
}
