.column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;

  &.extra-small > .item + .item {
    margin-top: 8px;
  }

  &.small > .item + .item {
    margin-top: 16px;
  }

  &.medium > .item + .item {
    margin-top: 24px;
  }

  &.large > .item + .item {
    margin-top: 32px;
  }
}

.item {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
