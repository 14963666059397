@import '../../../../../styles/common/colors';

.button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  padding: 0;

  border: none;
  background: none;

  .icon {
    width: 20px;
    height: 20px;
    fill: $gray;
  }

  &:hover .icon {
    fill: darken($gray, 10%);
  }
}