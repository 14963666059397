@import "../../../styles/common/colors";

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $smoke;

  &.really_bad {
    background-color: $dark-red;
  }

  &.bad {
    background-color: $dark-orange;
  }

  &.good {
    background-color: $dark-green;
  }

  &.neutral {
    background-color: $dark-sky-blue;
  }
}