@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.button {
  width: 100%;
  height: 115px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;

  cursor: pointer;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  border-radius: 4px;
  border: dashed 1px $light-gray;
  background-color: $snow;

  .icon {
    width: 24px;
    height: 24px;
    margin-bottom: 12px;
  }

  color: $sky-blue;
  fill: $sky-blue;

  &:hover {
    color: darken($sky-blue, 10%);
    fill: darken($sky-blue, 10%);
  }

  &:focus {
    background-color: $isabeline;
  }

  &.dragging {
    background-color: $smoke;
  }

  .input {
    display: none;
  }
}

.file {
  margin-top: 4px;
}
