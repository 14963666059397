@import "./DragHandle";
@import "../../../styles/common/colors";

.sort-cursor {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.highlight {
  border: 2px solid $light-sky-blue;
  -webkit-box-shadow: 0 15px 28px 0 $light-gray;
          box-shadow: 0 15px 28px 0 $light-gray;
}
