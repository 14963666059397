@import '../../../styles/common/text';

.input-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  width: 100%;

  @extend %paragraph;

  .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;

    .label {
      text-align: left;
      color: $dark-gray;
      margin-bottom: 4px;

      &.required:after {
        content: " *";
      }
    }

    .right-content{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }

  .icon {
    width: 18px;
    height: 18px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-left: 3px;
    margin-bottom:-3px;

    &.warning {
      fill: $dark-orange;
    }
  }

 
}



.validation-error {
  margin-top: 4px;
}

.help-text {
  margin-top: 4px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
