@import './mixins';

.input {
  @include input;

  &.large {
    height: 52px;
  }

  &.error {
    @include error;
  }
}

.input-wrapper {
  position: relative;
}

.right-element {
  position: absolute;
  right: 0;

  padding-right: 16px;
  height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: $gray;
}
