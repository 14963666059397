@import '../../styles/common/colors';
@import 'common/editorVars';

@mixin header-container() {
  display: flex;
  align-items: center;
  background-color: $sky-blue;
  height: $headerHeight;
  max-height: $headerHeight;
  flex-shrink: 0;
}

@mixin buttons-container() {
  display: flex;
  align-items: center;
  margin-right: 24px;

  & > *:not(:first-child) {
    margin-left: 15px;
  }
}

@mixin header-body-container() {
  height: 100%;

  .header {
    height: $headerHeight;
  }

  .body {
    height: calc(100% - #{$headerHeight});
    overflow-y: auto;
  }
}