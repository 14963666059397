.list {
  table {
    table-layout: fixed;
    width: 100%;
    padding-top: 15px;
  }

  table > thead > tr > td {
    height: 64px;
  }

  table > thead > tr > th, td {
    text-overflow: ellipsis;
    overflow: hidden;

    &:nth-child(1) {
      width: 200px;
      padding-left: 30px;
    }

    &:nth-child(2) {
      width: 300px;
      padding: 0 5px;
    }

    &:nth-child(3) {
      min-width: 200px;
    }

    &:nth-child(4) {
      text-align: right;
      min-width: 200px;
    }
  }
}