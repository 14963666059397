@import '../../../styles/common/colors';

.edit-icon {
  width: 32px;
  height: 32px;
  fill: $gray;
}

.edit-button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
}

.group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  line-height: 1;
}
