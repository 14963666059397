.list {
  width: 100%;

  table {
    padding-top: 15px;
  }

  table > thead > tr > th, td {

    &:nth-child(1) {
      width: 20px;
      padding: 0 28px 0 8px;
    }

    &:nth-child(2) {
      min-width: 240px;
    }

    &:nth-child(3) {
      width: 70%;
    }

    &:nth-child(4) {
      width: 30%;
    }

    &:nth-child(5) {
      width: 60px;
    }
  }
}