@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.whats-next {
  width: 560px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 45px 0 0;
}

.title {
  text-align: center;
  margin: 0;
}

.subtitle {
  line-height: 34px;
  font-size: 20px;
  color: $gray;
}

.list {
  @extend %paragraph;

  list-style-type: none;
  padding-left: 20px;
  margin: 20px 0 0;

  li::before {
    content: " ";
    display: block;
    margin-right: 10px;
    margin-bottom: 1px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $sky-blue;
    position: absolute;
    left: -18px;
    top: 6px;
  }

  li {
    font-size: 14px;
    color: $gray;
    line-height: 20px;
    text-align: left;
    position: relative;
  }

  li + li {
    margin-top: 10px;
  }
}

.button-container {
  margin: 30px 0 10px;
}