.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
}

.login-form {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 40px 32px;
  margin: auto;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.content {
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 100px; /* special padding to prevent mobile logo from overlapping */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.privacy-policy {
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.side-picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  height: 100%;
  z-index: -1;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.side-container {
  height: 100%;
  width: 460px;

  -webkit-box-flex: 0;

      -ms-flex-positive: 0;

          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  position: relative;

  .sevensenders-logo {
    margin: 40px;
    height: 56px;
    width: 56px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }

  .text-and-logos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 40px;

    .promotion-text {
      color: white;
      font-size: 14px;
      margin-bottom: 32px;
    }
    .logos {
      width: 460px;

      display: -webkit-box;

      display: -ms-flexbox;

      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
    }
  }
}

.sevensenders-logo-mobile {
  display: none;
  position: absolute;
  top: 40px;
}

@media (max-width: 1024px) and (min-width: 769px) {
  .side-container {
    display: none;
  }

  .sevensenders-logo-mobile {
    left: 40px;
    display: block;
  }
}

@media (max-width: 768px) {
  .side-container {
    display: none;
  }

  .sevensenders-logo-mobile {
    left: 32px;
    display: block;
  }
}
