@import "../../styles/common/colors";
@import "../../styles/common/text";

.item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: $charcoal-gray;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: 40px;
  letter-spacing: normal;

  /* some space between items, vertical margins will collapse into 2px gap */
  margin-top: 2px;
  margin-bottom: 2px;

  .title {
    margin: 10px 12px;
  }

  &:hover {
    color: $charcoal-gray;
    background-color: $isabeline;
    border-radius: 5px;
  }

  &:focus {
    color: $dark-sky-blue;
    background-color: rgba(0, 177, 225, 0.2);
    outline: none;
    border-radius: 5px;
  }

  &.selected {
    color: $dark-sky-blue;
    background-color: $light-gray;
    border-radius: 5px;
  }
}

.level0 {
  @extend %paragraph;
  margin-left: 0;

  &.selected {
    background-color: transparent;
  }

  &.selected-childless{
    color: $dark-sky-blue;
    background-color: $light-gray;
    border-radius: 5px;
  }
}

.level1 {
  @extend %paragraph;
  font-size: 13px;
  margin-left: 16px;
}

.disabled {
  cursor: default;
  color: $light-gray;
  pointer-events: none;
}

.disabled:hover {
  color: $light-gray;
  background-color: transparent;
}