@import '../../../styles/common/colors';
$modal-content-padding-left: 60px;
$modal-content-padding-right: 60px;
$modal-content-padding-bottom: 60px;
$modal-border-radius: 8px;
$common-padding : 30px 60px;

.title {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-top: 0;
  margin-bottom: 30px;
  padding: 60px 60px 0;
}

.header {
  height: $modal-border-radius;
  background-color: $sky-blue;
  padding: $common-padding;
}

.warnings-checklist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 8px 60px 40px;
  background-color: $snow ;

  h4 {
    margin: 30px 0 20px 0;
  }
}

.blockquote {
  padding: 0 60px;

  blockquote {
    background-color: $snow;
    padding: 16px 20px;
    margin: 10px 0 50px;
    border-radius: 8px;
  }
}

.icon + .title {
  -ms-flex-item-align: center;
      align-self: center;
  margin-top: 30px;
}