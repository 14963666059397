@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.button {
  font-family: 'Public Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  outline: none;

  color: $gray;
  fill: $gray;

  &:hover:not(:disabled) {
    color: $dark-gray;
    fill: $dark-gray;
  }

  &:focus {
    background-color: $isabeline;
  }

  &:disabled {
    opacity: 0.6;
  }
}
