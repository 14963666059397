@import "../../../styles/common/text";

%input-shared {
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 25px;
  border: 2px solid $light-gray;
  padding: 0 20px;
  background-color: $snow;

  @extend %paragraph;
  color: $gray;

  &:focus:not(:read-only) {
    border-color: $gray;
    outline: none;
  }

  &:hover:not(:focus):not(:read-only) {
    border-color: $smoke;
  }

  &:read-only {
    background-color: $smoke;
  }

  &:disabled {
    opacity: 0.3;
  }

  &::placeholder {
    color: $silver;
    font-weight: 300;
  }
}