@import '../../styles/common/colors';

.code {
  width: 552px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: 'Monaco', monospace;
  padding: 24px;
  border: none;
  color: $gray;
  background-color: $snow;
  border-radius: 8px;

  pre {
    white-space: pre-wrap;
    margin: 0px;
    font-size: 12px;
  }
}

.title {
  margin: 0 0 16px 1px;
  color: $gray;
}
