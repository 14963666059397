@import '../../styles/common/colors';

.item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-orient: vertical;

  -webkit-box-direction: normal;

      -ms-flex-direction: column;

          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  & > .text {
    padding-bottom: 4px;
    margin-right: 0px;
  }

  &.inline{
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;

    & > .text {
      margin-right: 4px;
      padding-bottom: 0px;
    }

  }
}

.small-badge {
  height: 20px;
  padding: 0 4px;
  border-radius: 2px;
  background-color: $dark-gray;
  font-size: 12px;
  line-height: 20px;
  color: $white;
  margin-right:4px;
  &.focus{
    background-color: $sky-blue;
  }
}

.text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
