@import '../../styles/common/colors';
@import '../../styles/common/text';
@import "../../styles/common/breakpoints";

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 12px;
  padding-top: 24px;

  & .text {
    font-family: 'Radikal', sans-serif;
    font-size: 20px;
    line-height: 32px;
    color: $dark-gray;

    @media (max-width: $mobile-breakpoint) {
      font-size: 16px;
    }
  }

  & .badge {
    margin-left: 16px;
  }
}

.menu {
  margin-top: 24px;
}


@media (max-width: $mobile-breakpoint) {
  .title {
    & .text {
      font-size: 16px;
      line-height: 24px;
    }


    & .badge {
      margin-left: 8px;
    }
  }

  .menu {
    margin-top: 12px;
  }
}