@import '../../../styles/common/colors';

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.heading {
  &.danger {
    color: $dark-red;
  }

  &.success {
    color: $dark-green;
  }
}
