@import '../../../styles/common/colors';

.input {
  font-size: 14px;
  line-height: 18px;
  padding: 16px 20px;
  border-radius: 8px;
  color: $gray;
  background-color: $snow;
  width: 540px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: left;
  margin: 15px 0 40px;
}