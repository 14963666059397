@import '../../../styles/common/colors';

.button {
  width: 24px;
  height: 24px;
  line-height: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background: none;
  outline: none;
  border-style: none;
  fill: $dark-gray;

  &:hover {
    fill: $gray;
  }
}
