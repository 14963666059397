@import '../../../../styles/common/colors';

.container {
  width: 100%;
  height: 100%;

  /* do not fill the space until loaded */
  &:not(.loaded) {
    display: none;
  }
}