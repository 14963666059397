@import '../../styles/common/colors';
@import '../../styles/common/text';
@import '../../styles/common/shadow';

.icon {
  fill: $silver;
  margin-left: -20px;
}

.tip {
  margin-bottom: 10px;
}

.tip-icon {
  fill: $sky-blue;
  margin-right: 8px;
}

.info {
  padding-right: 10px;
}

.icon {
  fill: $silver;
}

.name {
  color: $dark-gray;
  letter-spacing: 0.1px;
  margin-right: 10px;
}

.legacy-tracking-page{
  margin-bottom: 10px;
}