@import '../../../styles/common/colors';

.button {
  cursor: pointer;
  position: absolute;
  bottom: 100px;
  background-color: $sky-blue;
  z-index: 1;
  left: 317px;

  width: 32px;
  height: 32px;
  border-radius: 50%;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  .icon {
    fill: $white;
  }

  &.collapsed {
    left: 70px;
    right: inherit;
    -webkit-box-shadow: 0 0 15px 0 rgba(61, 67, 69, 0.1);
            box-shadow: 0 0 15px 0 rgba(61, 67, 69, 0.1);

    .icon {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
    }
  }
}