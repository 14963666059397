@import '../../../styles/common/badge';

.status-badge {
  @include large-badge($dark-gray);

  cursor: pointer;

  &.disabled {
    opacity: 0.3;
  }

  &.good {
    background-color: $dark-green;
  }

  &.really_bad {
    background-color: $dark-red;
  }

  &.bad {
    background-color: $dark-orange;
  }

  &.neutral {
    background-color: $dark-sky-blue;
  }
}