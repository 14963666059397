@import "../../../styles/common/colors";

.container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.list {
  margin-top: 16px;
}

.item {
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid $isabeline;
  border-bottom-width: 0;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-width: 1px;
  }

  .link {
    margin-left: auto;
    text-decoration: none;
  }
}