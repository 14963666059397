@import "../../../styles/common/colors";

.color {
  width:100%;
  height:100%;
  border-radius: 3px;
}

.swatch {
  background: $white;
  width: 50px;
  height: 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
  border: solid 2px $smoke;
  display: inline-block;
  cursor: pointer;
}