@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.item {
  height: 64px;
  min-height: 50px;
  padding-right: 20px;
  border: 2px solid $smoke;
  border-radius: 2px;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;

  &:hover {
    border: 2px solid $light-sky-blue;
  }

  & + .item {
    margin-top: 5px;
  }
}

.item-clickable {
  cursor: pointer;
}

.spacer {
  height: 20px;
}

.title {
  @extend %paragraph-title;
  width: 150px;
}

.children {
  margin-left: auto;
}

.arrow-right {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-left: auto;
  fill : $black
}

.icon {
  fill: $sky-blue;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.footer {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}