@import '../../../../styles/common/colors';

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 32px;
  border: 1px solid $isabeline;
  border-radius: 8px;
  min-width: 900px;
  padding: 20px;
  background-color: $white;
  margin-bottom: 24px;
}
