@import "../../../styles/common/breakpoints.scss";

.section {
  display: grid;
  gap: 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 100%;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: $mobile-breakpoint) {
  .section {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

.image {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
}