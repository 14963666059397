@import "../../styles/common/colors";

.collapsible-header {
  height: 64px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  padding: 24px;

  &.archived {
    cursor: default;
    opacity: 0.3;
  }
}

.elements {
  margin-left: auto;
  margin-right: 20px;
}

.collapse-button {
  width: 24px;
  height: 24px;
  fill: $dark-gray;

  &.expanded {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}