@import "../../../styles/common/colors";

.footer {
  height: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 24px;
  border-top: 1px solid $light-gray;
}