.table-header th, .table-body td {
  &:nth-child(1) {
    width: 100px;
  }

  &:nth-child(2) {
    width: 140px;
  }

  &:nth-child(3) {
    width: 160px;
  }

  &:nth-child(4) {
    width: 200px;
  }

  &:nth-child(5) {
    width: 120px;
  }

  &:nth-child(6) {
    width: 120px;
    padding-right: 0;
  }
}

.table-body {
  /* email address can be really long and better to show it without ellipsis */
  td:nth-child(4) {
    word-break: break-all;
  }
}
