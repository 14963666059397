.button-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: 30px;
}

.label {
  margin-left: 20px;
  -ms-flex-item-align: center;
      align-self: center;
}