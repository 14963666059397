.panel {
  min-width: 600px;

  .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    width: 100%;
    margin-bottom : 20px;

    h1 {
      white-space:nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0 10px 30px 0;
    }

    .filter-and-button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-left: auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
    }
  }
}
