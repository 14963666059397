@import '../../../styles/common/colors';

.item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  &::before {
    content: " ";
    margin-right: 16px;
    margin-top: 8px;
    width: 8px;
    height: 8px;
    min-width: 8px;
    border-radius: 50%;
    background-color: $sky-blue;
  }
}

.item + .item {
  margin-top: 16px;
}