@import '../../../styles/common/colors';

.link {
  color: $sky-blue;
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:active {
    color: mix(#000000, $sky-blue, 10%);
  }

  &.underline {
    color: $dark-gray;
    text-decoration: underline;
  }
}
