@import "../../styles/common/colors";

.wizard {
  width: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 42px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.button {
  width: 36px;
  height: 36px;
  fill: $gray;

  &.back {
    margin-right: auto;
  }

  &.close {
    margin-left: auto;
  }
}
