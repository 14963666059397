@import '../../../styles/common/colors';

.no-result {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 80px;

  .heading {
    margin-bottom: 8px;
  }

  .body {
    margin: 8px;
  }
}
