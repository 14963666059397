@import "../../styles/common/colors";
@import "../../styles/common/text";

.add-widget {
  height: 64px;
  min-height: 50px;
  padding: 4px;
  border: none;
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0 20px 5px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.title {
  @extend %paragraph-title;
  margin-left: 10px;
}

.fill {
  fill : $sky-blue;
}