@import "../../../styles/common/colors";
@import "../../../styles/common/shadow";

$modal-content-padding-left: 60px;
$modal-content-padding-right: 60px;
$modal-content-padding-bottom: 60px;
$modal-border-radius: 8px;

.modal {
  border-radius: $modal-border-radius;
  background-color: $white;
  padding: 0 !important;
  width: 680px;
}

.closeButton {
  top: 22px !important;
  right: 22px !important;
  cursor: pointer;
  outline: none;
}

.closeIcon {
  width: 36px;
  height: 36px;
  fill: $silver;

  &:hover {
    fill: darken($silver, 10%);
  }
}

.icon {
  -ms-flex-item-align: center;
      align-self: center;
}

.overlay {
  background-color: rgba($light-gray, 0.8) !important;
}

.header {
  height: $modal-border-radius;
  background-color: $sky-blue;
  border-top-left-radius: $modal-border-radius;
  border-top-right-radius: $modal-border-radius;
}

.header-warning {
  background-color: $orange;
}

.header-danger {
  background-color: red;
}

.header-success{
  background-color: $green;
}