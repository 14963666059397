@import "./colors";

@mixin badge($color) {
  display: inline-flex;
  align-items: center;
  border-radius: 2px;
  background-color: $color;
  color: $white;

  font-weight: normal;
  font-style: normal;

  white-space: nowrap;
}

@mixin large-badge($color) {
  @include badge($color);

  height: 26px;
  font-size: 14px;
  padding: 0 8px;
}

@mixin medium-badge($color) {
  @include badge($color);

  height: 24px;
  font-size: 12px;
  padding: 0 8px;
}

@mixin small-badge($color) {
  @include badge($color);

  height: 16px;
  font-size: 10px;
  padding: 0 4px;
}

@mixin badge-dot() {
  content: ' ';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $white;
  margin-right: 5px;
}