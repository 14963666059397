@import "../../../styles/common/card";
@import "../../../styles/common/colors";

.card {
  @include card(360px, auto, 0);

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin: 16px 0;
  border: 1px solid $isabeline;

  .heading {
    padding: 16px 24px;
    border-bottom: 1px solid $light-gray;
  }

  .content {
    padding: 5px 24px 10px 24px;
  }
}