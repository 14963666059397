@import '../../../styles/common/colors';

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 335px;
  height: 35px;
  border-radius: 8px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid $light-gray;
  border-right: transparent;
  background-color: $white;
  padding-left: 25px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;

  &.focused {
    border: 1px solid $sky-blue;
    border-right: transparent;
  }

  &:hover:not(.focused) {
    border-color: $silver;
    border-right: transparent;
  }

  .input {
    padding: 0;
    font-size: 14px;
    font-weight: 300;
    color: $gray;
    outline: none;
    border: none;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;

    &::-webkit-input-placeholder {
      color: $silver;
      font-weight: 300;
    }

    &::-moz-placeholder {
      color: $silver;
      font-weight: 300;
    }

    &::-ms-input-placeholder {
      color: $silver;
      font-weight: 300;
    }

    &::placeholder {
      color: $silver;
      font-weight: 300;
    }
  }

  .clear-button {
    height: 54px;
    outline: none;
    border: none;
    background: none;
    font-size: 14px;
    color: $silver;
    padding: 0;
    margin: 0 15px;
    cursor: pointer;

    &:hover {
      color: darken($silver, 10%);
    }

    &:active {
      color: darken($silver, 20%);
    }
  }

  .search-button {
    outline: none;
    border: none;
    padding: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 56px;
    height: 34px;
    background-color: $sky-blue;
    cursor: pointer;

    &:hover {
      background-color: darken($sky-blue, 10%);
    }

    &:active {
      background-color: darken($sky-blue, 20%);
    }

    .icon {
      width: 24px;
      height: 24px;
      fill: $white;
    }
  }
}
