@import '../../../styles/common/colors';

.instruction-card {
  padding: 72px 0 72px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  background-color: $white;
  border: solid 1px $isabeline;

  .items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    & > :not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.instruction-link {
  margin-top: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  .how-to {
    color: $dark-gray;
  }
}
