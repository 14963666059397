@import "../../../styles/common/shadow";
@import "../../../styles/common/colors";
@import "../../../styles/common/badge";

.preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  background-color: $white;
  padding: 50px;
  border-radius: 8px;

  @include shadow();

  .wrapper {
    min-width: 200px;
    border: solid 2px $snow;
    border-radius: 2px;
    padding: 10px;
  }

  .mobile-badge {
    @include large-badge($dark-green);
    margin-bottom: 20px;
    -ms-flex-item-align: start;
        align-self: flex-start;

    &:before {
      content: ' ';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $white;
      margin-right: 5px;
    }
  }
}