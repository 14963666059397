@import '../../styles/common/colors';

$modal-border-radius: 8px;

.bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 30px 60px 40px;
  background-color: $snow;
  border-bottom-left-radius: $modal-border-radius;
  border-bottom-right-radius: $modal-border-radius;

  .heading {
    margin-bottom: 20px;
  }
}
