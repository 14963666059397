@import "../../../styles/common/colors";

.menu {
  min-width: 200px;
  min-height: 20px;
  padding: 8px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 5px;
  background-color: $white;
  -webkit-box-shadow: 0 2px 15px 0 rgba(61, 67, 69, 0.1);
          box-shadow: 0 2px 15px 0 rgba(61, 67, 69, 0.1);
}
