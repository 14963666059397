@import '../../styles/common/colors';

.body {
  padding: 0 60px;

  .blockquote {
    background-color: $snow;
    padding: 16px 20px;
    margin: 10px 0 50px;
    border-radius: 8px;
  }
}