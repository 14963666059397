.dropdownTargetContainer {
  display: inline-block;
}

.dropdownPoint {
  position: absolute;
}

.dropdownPanel {
  position: absolute;
  z-index: 100;
  padding: 10px;
}

.right {
  left: 0
}

.left {
  right: 0
}

.bottom {
  top: 0
}

.top {
  bottom: 0
}