@import '../../styles/common/colors';

.page {
  --header-height: 60px;
  --footer-height: 60px;
  --menu-height: calc(100vh - var(--header-height) - var(--footer-height));

  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  & > header {
    position: relative;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    width: 100%;
    height: var(--header-height);
    padding: 0 16px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    background-color: $white;
    border-bottom: 1px solid $silver;
  }

  & > footer {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    width: 100%;
    height: var(--footer-height);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    background-color: $white;
  }

  & > .content {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: 0;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $snow;
    padding: 24px;
  }
}

.menu-container {
  position: absolute;
  top: var(--header-height);
  left: 0;
  width: 240px;
  max-width: 240px;
  height: var(--menu-height);
  max-height: var(--menu-height);
  z-index: 9999999;
}

.menu-button {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;

  &:active {
    background: $smoke;
  }

  fill: $black;
}

.logo {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  fill: $sky-blue;
  background-color: $white;
}
