@import '../../../styles/common/text';
@import '../../../styles/common/colors';

.title {
  @extend %paragraph;
  font-size: 14px;
  color: $dark-gray;
}

.message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  .badge {
    margin-left: auto;
    padding-left: 5px;
  }
}

.edit-icon {
  width: 32px;
  height: 32px;
  fill: $gray;

  &:hover {
    fill: $silver;
  }
}