@import '../../../styles/common/colors';

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 50%;

  &.info {
    background-color: $lightest-sky-blue-two;
    fill: $sky-blue;
  }

  &.success {
    background-color: $lightest-green;
    fill: $dark-green;
  }

  &.danger {
    background-color: $lightest-red;
    fill: $dark-red;
  }

  &.warning {
    background-color: $lightest-orange;
    fill: $dark-orange;
  }
}