@import '../../../styles/common/colors';

.claims-table {
  width: 940px;
  border-spacing: 0px;
  background-color: $white;
  border-collapse: separate;
  table-layout: fixed;
  border-left: 1px solid $isabeline;
  border-right: 1px solid $isabeline;

  thead {
    position: sticky;
    top: 289px;
  }

  th {
    border-top: 1px solid $isabeline;
    padding: 14px 15px;
  }

  td,
  th {
    background: white;
    padding: 20px 15px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid $isabeline;
    display: table-cell;
  }

  tr {
    td:nth-child(1),
    th:nth-child(1) {
      width: 125px;
    }

    td:nth-child(2),
    th:nth-child(2) {
      width: 118px;
    }

    td:nth-child(3),
    th:nth-child(3) {
      width: 75px;
    }

    td:nth-child(4),
    th:nth-child(4) {
      width: 118px;
    }

    td:nth-child(6),
    th:nth-child(6) {
      width: 50px;
    }

    td:nth-child(6) {
      padding: 20px 0;
    }
  }
}
