@import "../../../styles/common/colors";


.container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;

  & .icon {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    width: 20px;
    height: 20px;
    fill: $dark-red;
    margin-right: 4px;
  }

  & .message {
    color: $dark-red;
  }
}