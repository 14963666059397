@import "../../styles/common/colors";

.popup {
  border-radius: 8px;
  background-color: $white;
  -webkit-box-shadow: 0 2px 10px 0 rgba(241, 241, 241, 0.5), 0 2px 10px 0 rgba(241, 241, 241, 0.5);
          box-shadow: 0 2px 10px 0 rgba(241, 241, 241, 0.5), 0 2px 10px 0 rgba(241, 241, 241, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-negative: 0;
      flex-shrink: 0;


  .topline {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    width: 100%;
    height: 8px;
    background-color: $sky-blue;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .columns {
    padding: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.close {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-left: auto;
  padding-left: 32px;
  cursor: pointer;
  font-size: large;
  height: 16px;
  width: auto;
  fill: $sky-blue;
}