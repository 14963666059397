@import '../../../styles/common/colors';

.container {
  width: 375px;
  border-radius: 5px;
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;

  .item {
    border-radius: 5px;
    background-color: $white;
    border: 1px solid $isabeline;
  }

  .heading {
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    .icon {
      margin-right: 7px;
    }
  }

  .controls {
    padding: 16px;
  }

  .preview {
    overflow: hidden; /* for rounded corners because child doesn't have them */
    height: 516px;
  }
}

