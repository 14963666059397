@import '../../../common2/BasicInput/mixins';

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
}

.input {
  @include input;

  -webkit-appearance: none;

     -moz-appearance: none;

          appearance: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  &.error {
    @include error;
  }
}

.select-wrapper {
  position: relative;
  min-width: 74px;
}

.select {
  @include input;
  cursor: pointer;

  -webkit-appearance: none;

     -moz-appearance: none;

          appearance: none;
  padding-right: 12px;
  border-left: none;

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:hover {
    background-color: $isabeline;
  }
}

.arrow-element {
  position: absolute;
  right: 0;
  top: 0;

  pointer-events: none;

  height: 36px;
  padding: 0 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  fill: $dark-gray;
}