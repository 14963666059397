@import '../../../styles/common/colors';

.tabs {
  height: 40px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom: 1px solid $isabeline;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
}