@import "../../../styles/common/colors";

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 64px;
  padding: 0 24px;
  background-color: $snow;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  position: relative;

  .button {
    position: absolute;
    left: 24px;
    line-height: 0;
  }
}