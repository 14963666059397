@import '../../../styles/common/colors';

.drop-zone {
  width: 100%;
  height: 115px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;

  cursor: pointer;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  border-radius: 4px;
  border: dashed 1px $light-gray;
  background-color: $snow;

  &.dragging {
    background-color: $smoke;
  }

  .input {
    display: none;
  }

  &:disabled {
    opacity: 0.6;
  }
}
