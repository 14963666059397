@import "../../../../styles/common/colors";

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  position: absolute;
  top: 6px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
 
  background: $lightest-orange;
  padding: 2px 4px;
  border-radius: 5px;
  
  max-width: 90%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;

  .message {
    color: $dark-orange;

    overflow-wrap: anywhere;
    text-align: center;
  }
}

.warning-icon {
  fill: $dark-orange;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}