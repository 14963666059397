@import '../../../styles/common/colors';

.icon {
  cursor: pointer;
  fill: $dark-gray;
}

.button {
  background: transparent;
  border: none;
  outline: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  &:focus {
    border: none;
  }
}

.menu {
  min-width: 144px;
  min-height: 40px;
  padding: 4px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 4px;
  background-color: $white;
  -webkit-box-shadow: 0 0 0 1px #ececec, 0 2px 16px 0 rgba(45, 50, 51, 0.1);
          box-shadow: 0 0 0 1px #ececec, 0 2px 16px 0 rgba(45, 50, 51, 0.1);
}
