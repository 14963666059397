@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.status {
  @extend %paragraph-small;

  padding: 1px 8px;
  border-radius: 2px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 16px;
  background-color: $gray;
  color: $white;

  &.neutral {
    background-color: $isabeline;
    color: $gray;
  }

  &.published {
    background-color: $green;
  }
}
