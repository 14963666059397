@import '../../editors/common/editorVars';
@import '../../editors/mixins';
@import '../../../styles/common/colors';

.panel {
  @include header-body-container();

  overflow-y: auto;

  .header {
    color: $white;
    background-color: $dim-sky-blue;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    .back-button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;

      width: 60px;
      height: 60px;
      cursor: pointer;
    }

    .back-icon {
      fill: $white;
      width: 16px;
      height: 16px;
    }

    .title {
      font-size: 18px;
    }
  }

  .body {
    background-color: $white;
  }
}