@import "../../styles/common/colors";
@import "../../styles/common/breakpoints";

.features {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 24px;
}

.greeting {
  margin-bottom: 24px;
}

.title {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 2px solid $sky-blue;
  border-radius: 1px;
  margin-bottom: 24px;
}

.help-section {
  margin-top: 24px;

  .cards {
    display: grid;
    grid-template-columns: 288px 288px 288px;
    grid-gap: 24px;
    margin: 24px 0;
  }
}

@media (max-width: $mobile-breakpoint){
  .features {
    grid-template-columns: auto;
  }

  /*
   * Workaround to hide "Explore" title when no feature cards displayed
   * When it's mobile we hide all cards except "chatbot" but when JS code doesn't render "chatbot" (no permissions)
   * we suddenly don't have visible cards at all: "chatbot" doesn't exist and everything else is hidden
   */
  :not(:has(.features > [data-product-id="chatbot"])) > .title {
    display: none;
  }

  .features > *:not([data-product-id="chatbot"]) {
    display: none;
  }

  .title {
    margin-bottom: 12px;
  }

  .greeting {
    margin-bottom: 12px;
  }

  .help-section {
    display: none;
  }
}