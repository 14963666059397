$white: #ffffff;
$snow: #f8f8f8;
$smoke: #f1f1f1;
$isabeline: #ececec;
$light-gray: #e5e5e5;
$silver: #c5c7c7;
$gray: #8b9294;
$dark-gray: #3d4345;
$black: #2d3233;
$light-sky-blue: #00c8ff;
$lightest-sky-blue-two: #d0f0f9;
$sky-blue: #00b1e1;
$dim-sky-blue: #00a6d4;
$dark-sky-blue: #00a0cc;
$midnight-blue: #00647f;
$dark-midnight-blue: #005269;
$navi-blue: #004a5e;
$lightest-green: #dbf3e6;
$green-light: #40ff99;
$green: #19e678;
$dark-green: #39bf78;
$lightest-orange: #faebd0;
$light-orange: #ffb940;
$orange: #ffa300;
$dark-orange: #e69100;
$lightest-red: #f5d8dd;
$light-red: #ff8095;
$red: #fa3253;
$dark-red: #cc2944;
$dark-green: #39bf78;
$charcoal-gray: #3d454d;
