@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}

.subheader {
  margin: 20px 0 2px 0;
  font-size: 20px;
  line-height: 34px;
  color: $gray;
}

.header {
  @extend %large;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.help-icon {
  vertical-align: middle;
  margin-left: 15px;
}

.icon {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 210px;
  height: 210px;
  margin-top: 65px;
  margin-bottom: 75px;
}

.fields {
  width: 540px;
  margin-bottom: 30px;
}

.button {
  margin-bottom: 60px;
}

.pagination {
  margin-top: auto;
  margin-bottom: 60px;
}