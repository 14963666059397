@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.button {
  font-family: 'Public Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  padding: 0;

  border: none;
  background: none;
  outline: none;

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  color: $sky-blue;
  fill: $sky-blue;

  &:hover:not(:disabled) {
    color: darken($sky-blue, 10%);
    fill: darken($sky-blue, 10%);
  }

  &:focus {
    background-color: $isabeline;
  }

  &:disabled {
    opacity: 0.6;
  }
}

@mixin button-color($color) {
  $hover-color: mix(#000000, $color, 10%);
  $focus-color: mix(#000000, $color, 20%);

  color: $color;

  &:hover:not(:disabled) {
    color: $hover-color;
  }

  &:focus {
    border-color: $focus-color;
  }
}

@mixin icon-color($color) {
  $hover-color: mix(#000000, $color, 10%);
  $focus-color: mix(#000000, $color, 20%);

  .icon {
    fill: $color;
  }
}

.warning {
  @include button-color($dark-orange);
  @include icon-color($dark-orange);
}
