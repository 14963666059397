@import "../../../styles/common/colors";
@import "../../../styles/common/text";

.tab {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;

  padding: 15px 30px;
  margin: 0;
  cursor: pointer;

  border-style: solid;
  border-width: 0;
  border-bottom-width: 2px;
  border-color: $smoke;

  font-family: Radikal, 'sans-serif';
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: $dark-gray;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  &:hover {
    border-color: $sky-blue;
  }
}

.tab-title {
  text-align: center;
  width: 100%;
}

.editor-tab {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 48px;
  border-color: $smoke;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;

  .tab-title {
    @extend %paragraph;
    text-align: center;
  }
}

.selected-tab {
  border-color: $sky-blue;
}

.disabled-tab {
  cursor: default;
  color: lightgray;
  pointer-events: none;
}

.navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
  border-bottom-width: 0;
}