@import '../../../styles/common/colors';

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; /* when error message is multiline we want to align it to the top */

  .icon {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    padding-top: 2px; /* visually align icon with the first line of the error message */
    width: 20px;
    height: 20px;
    fill: $dark-red;
  }

  .text {
    margin-left: 4px;
    color: $dark-red;
  }
}
