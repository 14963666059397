@import '../../../../styles/common/colors';

.link {
  color: $gray;
  cursor: pointer;

  &:hover {
    color: $dark-gray;
  }
}
