@import '../../../styles/common/colors';

.container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px 16px;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: $snow;
  fill: $silver;

  .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: auto;
  }

  .icon {
    width: 24px;
    height: 24px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-right: 12px;
  }

  &.info {
    background-color: $lightest-sky-blue-two;
    fill: $sky-blue;
  }

  &.danger {
    background-color: $lightest-red;
    fill: $dark-red;
  }

  &.success {
    background-color: $lightest-green;
    fill: $dark-green;
  }

  &.warning {
    background-color: $lightest-orange;
    fill: $dark-orange;
  }
}
