.section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.title {
  text-transform: uppercase;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 1.75px;
  margin-bottom: 20px;
}
