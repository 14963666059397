@import "../../../styles/common/colors";

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  .icon {
    width: 24px;
    height: 24px;
    fill: $sky-blue;
  }

  .text {
    margin-top: 10px;
    color: $sky-blue;
  }

}