.card {
  width: 400px;
  -ms-flex-item-align: center;
      align-self: center;
}

.heading {
  margin-bottom: 30px;
}

.select-lang {
  margin-bottom: 20px;
}

.button {
  margin-left: auto;
}