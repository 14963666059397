@import '../../../../styles/common/colors';

.tab {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 8px;
  cursor: pointer;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;

  width: 200px;
  height: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: 1px solid isabeline;

  &.selected {
    padding-bottom: 7px;
    border-bottom: 2px solid $sky-blue;
  }
}

.text {
  padding: 4px 8px;

  &.selected {
    color: $sky-blue;
  }

  &:hover {
    background-color: $snow;
    border-radius: 4px;
  }
}
