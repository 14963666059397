@import '../../../styles/common/colors';
@import '../../../styles/common/text';

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  & > li {
    position: relative;
    padding-left: 24px;
    margin-bottom: 16px;
  }

  & > li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 9px;
    width: 8px;
    height: 8px;
    background-color: $sky-blue;
    border-radius: 50%;
  }
}

.emphasis {
  @extend %paragraph;
  color: $dark-gray;
  font-style: normal;
}

.link {
  text-decoration: none;
  color: $sky-blue;

  &:hover,
  &:active {
    color: mix(#000000, $sky-blue, 10%);
  }
}