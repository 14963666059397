@import '../../../../styles/common/colors';
@import '../../../../styles/common/text';

.title {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 10px;
  color: $dark-gray;

  &:hover {
    color: $sky-blue;
  }
}

.info:hover .localization-url {
  color: $sky-blue;
}

.links {
  @extend %paragraph-small;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 2px;

  .separator {
    width: 1px;
    height: 10px;
    background-color: $silver;
    margin: 0 5px;
  }

  .preview-link {
    color: $gray;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .live-link {
    color: $gray;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
