@import '../../styles/common/colors.scss';

.content {
  width: 942px;
  padding: 72px 0 72px 0;
  border-radius: 5px;
  background-color: $white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  .icon {
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: $lightest-sky-blue-two;
    border-radius: 50%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    fill: $sky-blue;
  }

  .heading {
    margin: 16px 0;
  }
}

.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
}
