.filter-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  .lang-select {
    margin-left: 15px;
  }

  .input {
    margin-left: auto;
    width: 320px;
  }

  .status-badge {
    margin-right: 15px;
  }

  margin-bottom: 10px;
}

.help {
  margin: 30px auto;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}