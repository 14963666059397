@import '../../styles/common/colors';

.input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  padding: 0 19.3px 0 19.3px ;
  border-radius: 0.5em;
  color: $gray;
  background-color: $snow;
  width: 600px;
  min-height: 50px;
  text-align: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 15px;
  margin-bottom: 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.icon-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 40px;

  .icon {
    width: 210px;
    height: 210px;
  }
}

.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-top: 80px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: $snow;
}