@import '../../../styles/common/colors';
@import '../../../styles/common/breakpoints';

.card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  padding: 40px 32px;
  border-radius: 8px;

  background-color: $white;
  border: 1px solid $isabeline;
}

@media (max-width: $mobile-breakpoint){
  .card {
    padding: 24px;
  }
}

.heading {
  margin-bottom: 8px;
}

.description {
  max-width: 624px;
  margin-bottom: 20px;
}