@import '../../../../styles/common/badge';
@import '../../../../styles/common/colors';
@import '../editorVars';

$hover-color: mix(#000000, $midnight-blue, 10%);
$focus-color: mix(#000000, $midnight-blue, 20%);

.exit-btn {
  width: $headerHeight;
  height: $headerHeight;
  background-color: $midnight-blue;
  border: 0;
  outline: none;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  cursor: pointer;

  &:hover {
    background-color: $hover-color;
  }

  &:active, &:focus {
    background-color: $focus-color;
  }
}

.inner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.icon {
  fill: $white;
  width: 16px;
  height: 16px;
}