@import '../../styles/common/colors';

.item {
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 12px;
  cursor: pointer;

  &:hover,
  &:focus,
  &.selected {
    border-radius: 50%;
  }

  &:hover {
    background-color: mix(#000000, $sky-blue, 10%);
  }

  &:focus {
    background-color: mix(#000000, $sky-blue, 30%);
  }

  &.selected {
    background-color: mix(#000000, $sky-blue, 20%);
  }

}