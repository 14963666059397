@import '../../../styles/common/colors';
@import '../../editors/common/editorVars';

.exit-header {
  width: $sidePanelWidth;
  background-color: $dim-sky-blue;
  color: $white;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;

  .title {
    font-weight: 300;
    margin: 0 5px 0 20px;
    line-height: $headerHeight;
    font-size: 18px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}