@import '../../../styles/common/colors';

.default-badge {
  display: inline-block;
  width: 70px;
  height: 24px;
  border-radius: 12px;
  color: $white;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  background-color: $midnight-blue;
}